import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Actions.module.sass";
import SpotForm from "./SpotForm";
import { useDispatch, useSelector } from "react-redux";
import { userSocket } from "socket.js";
import { USER_ENDPOINTS } from "socket/utils.js";
import { setTokenBalance } from "store/slices/userSlice.js";
import { useTranslation } from "react-i18next";

const SpotFormWrapper = () => {
  const { t } = useTranslation();
  const navigation = [
    t("navigation.limit"),
    t("navigation.market"),
    t("navigation.stop_limit"),
  ];
  const [isBuy, setIsBuy] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleAction, setVisibleAction] = useState(false);
  const [visible, setVisible] = useState(false);

  //Reusable copmonents
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const baseTokenSymbol = useSelector(
    (state) => state.symbolData.pairInfo.baseTokenSymbol
  );
  const quoteTokenSymbol = useSelector(
    (state) => state.symbolData.pairInfo.quoteTokenSymbol
  );
  const prevBaseTokenSymbol = useSelector(
    (state) => state.symbolData.prevPairData.baseTokenSymbol
  );
  const prevQuoteTokenSymbol = useSelector(
    (state) => state.symbolData.prevPairData.quoteTokenSymbol
  );
  const symbol = useSelector((state) => state.symbolData.symbol);

  useEffect(() => {
    // Listen for 'message' event from server
    userSocket.on(
      `${USER_ENDPOINTS.balance(userId, baseTokenSymbol)}`,
      (data) => {
        console.log("user balance" , data)
        dispatch(
          setTokenBalance({
            tokenSymbol: baseTokenSymbol,
            data,
          })
        );
      }
    );
    userSocket.on(
      `${USER_ENDPOINTS.balance(userId, quoteTokenSymbol)}`,
      (data) => {
        console.log("user balance" , data)
        dispatch(
          setTokenBalance({
            tokenSymbol: quoteTokenSymbol,
            data,
          })
        );
      }
    );
    // Clean up the effect
    return () => {
      userSocket.off(`${USER_ENDPOINTS.balance(userId, prevBaseTokenSymbol)}`);
      userSocket.off(`${USER_ENDPOINTS.balance(userId, prevQuoteTokenSymbol)}`);
    };
  }, [symbol, userId]);

  const relistenSocket = async () => {
    if (userId) {
      userSocket.emit(
        `unsubscribe-${USER_ENDPOINTS.BALANCE}`,
        prevBaseTokenSymbol
      );
      userSocket.emit(
        `unsubscribe-${USER_ENDPOINTS.BALANCE}`,
        prevQuoteTokenSymbol
      );
      userSocket.emit(`subscribe-${USER_ENDPOINTS.BALANCE}`, baseTokenSymbol);
      userSocket.emit(`subscribe-${USER_ENDPOINTS.BALANCE}`, quoteTokenSymbol);
    }
  };
  useEffect(() => {
    relistenSocket();
  }, [symbol, userId]);

  //END SYNC

  const handleClickBuy = () => {
    setVisibleAction(true);
    setVisible(true);
  };

  const handleClickSell = () => {
    setVisibleAction(false);
    setVisible(true);
  };

  return (
    <div className={styles.actions}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            marginBottom: "24px",
            cursor: "pointer",
            border: "0.85px solid #777E90",
            borderRadius: "26px",
            width: "81%",
          }}
          className={styles.typelink_wrapper}
        >
          <div
            className={styles.typelink}
            style={{
              width: "100%",
              borderRadius: "26px",
              ...(isBuy && { background: "#58BD7D" }),
            }}
            onClick={() => {
              setIsBuy(true);
            }}
          >
            {t("actions.buy")}
          </div>
          <div
            className={styles.typelink}
            style={{
              width: "100%",
              borderRadius: "26px",
              ...(!isBuy && { background: "#FF6838" }),
            }}
            onClick={() => {
              setIsBuy(false);
            }}
          >
            {t("actions.sell")}
          </div>
        </div>
      </div>
      <div className={styles.head}>
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
      </div>
      <div className={cn(styles.wrapper, { [styles.show]: visible })}>
        {
          <SpotForm
            limit={activeIndex === 0 || activeIndex === 2}
            stop={activeIndex === 2}
            market={activeIndex === 1}
            visible={visibleAction}
            setValue={setVisible}
            isBuy={isBuy}
          />
        }
      </div>
      <div className={styles.btns}>
        <button
          className={cn(isBuy ? "button-green" : "button-green", styles.button , styles.trade_but)}
          style={{
            background: isBuy ? "#58BD7D" : "#FF6838",
            width: "100%",
          }}
          onClick={() => (isBuy ? handleClickBuy() : handleClickSell())}
        >
          {isBuy ? t("actions.buy") : t("actions.sell")}
        </button>
      </div>
    </div>
  );
};

export default SpotFormWrapper;
