import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import cn from "classnames";
import styles from "./Table.module.sass";
import Dropdown from "../../../components/Dropdown";
import { userSocket } from "socket.js";
import { USER_ENDPOINTS } from "socket/utils.js";
import {
  setSpotOrders,
  setSpotOrdersPast,
  setSpotTrades,
} from "store/slices/userSlice.js";
import helper from "utils/helper.js";
import { syncImage } from "store/slices/tradingPairSlice.js";
import api from "utils/api.js";
import toast from "react-hot-toast";
import Scrollbar from "components/Scrollbar/index.jsx";
import { useTranslation } from "react-i18next";
import { ORDER_STATUS } from "utils/types.js";

const TableSpot = () => {
  const { t } = useTranslation();
  const navigation = [
    t("navigation.open_orders"),
    t("navigation.order_history"),
    t("navigation.executed_history"),
    t("navigation.trade_history"),
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(navigation[0]);
  const userId = useSelector((state) => state.user.userId);
  const symbol = useSelector((state) => state.symbolData.symbol);
  const spotOrders = useSelector((state) => state.user.spotOrders);
  const spotOrdersPast = useSelector((state) => state.user.spotOrdersPast);
  const spotTrades = useSelector((state) => state.user.spotTrades);
  const pairImageMap = useSelector((state) => state.tradingPair.image);
  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const precesion = useSelector((state) => state.symbolData.precesion);

  const dispatch = useDispatch();

  const callSyncImage = (pair) => {
    if (!pairImageMap[pair]) dispatch(syncImage(pair));
    return true;
  };
  const cancelOrder = async (orderId) => {
    try {
      toast.promise(
        api.cancelOrderSpot({ orderId }),
        {
          loading: t("toast_messages.cancelling_order"),
          success: (result) => {
            console.log(result);
            return t("toast_messages.order_cancelled");
          },
          error: (e) => {
            console.log(e);
            return t("toast_messages.cancel_failed");
          },
        },
        {
          style: {
            minWidth: "250px",
          },
        }
      );
    } catch (error) {
      console.log("error at cancel order", error);
    }
  };
  useEffect(() => {
    console.log("spot- emit listen");
    userSocket.on(USER_ENDPOINTS.spotOrders(userId, symbol), (data) => {
      dispatch(setSpotOrders(data));
    });
    userSocket.on(USER_ENDPOINTS.spotOrdersPast(userId, symbol), (data) => {
      dispatch(setSpotOrdersPast(data));
    });
    userSocket.on(USER_ENDPOINTS.spotTrades(userId, symbol), (data) => {
      dispatch(setSpotTrades(data));
    });

    return () => {
      if (userId) {
        console.log("spot- emit stop listening");
        userSocket.off(USER_ENDPOINTS.spotOrders(userId, symbol));
        userSocket.off(USER_ENDPOINTS.spotTrades(userId, symbol));
        userSocket.off(USER_ENDPOINTS.spotOrdersPast(userId, symbol));
      }
    };
  }, [userId, symbol]);

  useEffect(() => {
    if (userId) {
      console.log("spot- emit subscribe");
      userSocket.emit(`subscribe-${USER_ENDPOINTS.SPOT_ORDERS}`, symbol);
      userSocket.emit(`subscribe-${USER_ENDPOINTS.SPOT_TRADES}`, symbol);
      userSocket.emit(`subscribe-${USER_ENDPOINTS.SPOT_ORDERS_PAST}`, symbol);
    }
  }, [userId, symbol]);

  return (
    <div className={styles.inner}>
      <Dropdown
        className={styles.dropdown}
        value={sorting}
        setValue={setSorting}
        options={navigation}
      />
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      <div
        style={{
          height: "260px",
          position: "relative",
          right: "1px",
          bottom: "1px",
        }}
      >
        {activeIndex === 0 && (
          <Scrollbar style={{}}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className="">{t("table.spot_pairs")}</div>
                </div>

                <div className={styles.col}>
                  <div className="">{t("table.order_type")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.direction")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_value")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.limit_price")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.stop_price")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.filled_quantity")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_time")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_id")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.action")}</div>
                </div>
              </div>
              {spotOrders.map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    {pairImageMap && callSyncImage(x.tradingPair) && (
                      <img
                        style={{
                          height: "16px",
                          width: "16px",
                          marginRight: "6px",
                        }}
                        src={pairImageMap[x.tradingPair] ?? ""}
                        alt=""
                      />
                    )}
                    {x.tradingPair}
                  </div>
                  <div className={styles.col}>{x.orderType} </div>
                  <div className={styles.col}>{x.tradeType} </div>
                  <div className={styles.col}>
                    {helper.formatValue(
                      x.baseTokenAmount ?? x.quoteTokenAmount,
                      precesion
                    )}
                  </div>
                  <div className={styles.col}>
                    {x.limitPrice
                      ? helper.formatValue(x.limitPrice, precesion)
                      : "--"}
                  </div>
                  <div
                    className={styles.col}
                    style={{
                      background: x.isStopPriceReached
                        ? x.tradeType === "buy"
                          ? "#58bd7d"
                          : "#FF6838"
                        : "transparent",
                    }}
                  >
                    {x.stopPrice
                      ? helper.formatValue(x.stopPrice, precesion)
                      : "--"}
                  </div>
                  <div
                    className={styles.col}
                    style={{ display: "flex", gap: "4px" }}
                  >
                    {helper
                      .formatValue(x.filledAmountToken, precesion)
                      .toString() +
                      "/" +
                      helper
                        .formatValue(
                          x.baseTokenAmount ?? x.quoteTokenAmount,
                          precesion
                        )
                        .toString()}
                  </div>

                  <div className={styles.col}>
                    {helper.formatTime(x.createdAt)}
                  </div>
                  <div className={styles.col}>{x.id}</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className={cn("button-red", "button-mini", "button")}
                      onClick={() => {
                        cancelOrder(x.id);
                      }}
                    >
                      {t("table.cancel_order")}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </Scrollbar>
        )}
        {activeIndex === 1 && (
          <Scrollbar style={{}}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className="">{t("table.spot_pairs")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_type")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.direction")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_status")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_value")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.limit_price")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.stop_price")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.filled_quantity")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_time")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_id")}</div>
                </div>
              </div>
              {spotOrdersPast.map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    {pairImageMap && callSyncImage(x.tradingPair) && (
                      <img
                        style={{
                          height: "16px",
                          width: "16px",
                          marginRight: "6px",
                        }}
                        src={pairImageMap[x.tradingPair] ?? ""}
                        alt=""
                      />
                    )}
                    {x.tradingPair}
                  </div>
                  <div className={styles.col}>{x.orderType} </div>
                  <div className={styles.col}>{x.tradeType} </div>
                  <div className={styles.col}>
                    {helper.getOrderStatusText(x.orderStatus)}{" "}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(
                      x.baseTokenAmount ?? x.quoteTokenAmount,
                      precesion
                    )}
                  </div>
                  <div className={styles.col}>
                    {x.limitPrice
                      ? helper.formatValue(x.limitPrice, precesion)
                      : "--"}
                  </div>
                  <div
                    className={styles.col}
                    style={{
                      background: x.isStopPriceReached
                        ? x.tradeType === "buy"
                          ? "#58bd7d"
                          : "#FF6838"
                        : "transparent",
                    }}
                  >
                    {x.stopPrice
                      ? helper.formatValue(x.stopPrice, precesion)
                      : "--"}
                  </div>
                  <div
                    className={styles.col}
                    style={{ display: "flex", gap: "4px" }}
                  >
                    {helper
                      .formatValue(x.filledAmountToken, precesion)
                      .toString() +
                      "/" +
                      helper
                        .formatValue(
                          x.baseTokenAmount ?? x.quoteTokenAmount,
                          precesion
                        )
                        .toString()}
                  </div>

                    <div className={styles.col}>
                      {helper.formatTime(x.createdAt)}
                    </div>
                    <div className={styles.col}>{x.id}</div>
                  </div>
                ))}
            </div>
          </Scrollbar>
        )}
        {activeIndex === 2 && (
          <Scrollbar style={{}}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className="">{t("table.spot_pairs")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_type")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.direction")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_status")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_value")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.limit_price")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.stop_price")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.filled_quantity")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_time")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_id")}</div>
                </div>
              </div>
              {spotOrdersPast
              .filter((item) => {
                return item.orderStatus === ORDER_STATUS.executed;
              })
              .map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    {pairImageMap && callSyncImage(x.tradingPair) && (
                      <img
                        style={{
                          height: "16px",
                          width: "16px",
                          marginRight: "6px",
                        }}
                        src={pairImageMap[x.tradingPair] ?? ""}
                        alt=""
                      />
                    )}
                    {x.tradingPair}
                  </div>
                  <div className={styles.col}>{x.orderType} </div>
                  <div className={styles.col}>{x.tradeType} </div>
                  <div className={styles.col}>
                    {helper.getOrderStatusText(x.orderStatus)}{" "}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(
                      x.baseTokenAmount ?? x.quoteTokenAmount,
                      precesion
                    )}
                  </div>
                  <div className={styles.col}>
                    {x.limitPrice
                      ? helper.formatValue(x.limitPrice, precesion)
                      : "--"}
                  </div>
                  <div
                    className={styles.col}
                    style={{
                      background: x.isStopPriceReached
                        ? x.tradeType === "buy"
                          ? "#58bd7d"
                          : "#FF6838"
                        : "transparent",
                    }}
                  >
                    {x.stopPrice
                      ? helper.formatValue(x.stopPrice, precesion)
                      : "--"}
                  </div>
                  <div
                    className={styles.col}
                    style={{ display: "flex", gap: "4px" }}
                  >
                    {helper
                      .formatValue(x.filledAmountToken, precesion)
                      .toString() +
                      "/" +
                      helper
                        .formatValue(
                          x.baseTokenAmount ?? x.quoteTokenAmount,
                          precesion
                        )
                        .toString()}
                  </div>

                  <div className={styles.col}>
                    {helper.formatTime(x.createdAt)}
                  </div>
                  <div className={styles.col}>{x.id}</div>
                </div>
              ))}
            </div>
          </Scrollbar>
        )}
        {activeIndex === 3 && (
          <Scrollbar style={{}}>
            <div className={styles.table}>
              <div className={cn(styles.row, styles.table_header)}>
                <div className={styles.col}>
                  <div>{t("table.spot_pairs")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.trade_id")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.direction")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.price")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.amount")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.fee")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.order_value")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.executed_at")}</div>
                </div>
              </div>
              {spotTrades.map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    {pairImageMap && callSyncImage(x.tradingPair) && (
                      <img
                        style={{
                          height: "16px",
                          width: "16px",
                          marginRight: "6px",
                        }}
                        src={pairImageMap[x.tradingPair] ?? ""}
                        alt=""
                      />
                    )}
                    {x.tradingPair}
                  </div>
                  <div className={styles.col}>{x.id} </div>
                  <div className={styles.col}>
                    {x.sellerUserId === userId ? "sell" : "buy"}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(x.tradePrice, precesion)}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(x.baseTokenAmount, precesion) +
                      " " +
                      x.baseTokenSymbol}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(
                      x.sellerUserId === userId
                        ? x.sellerTradeFee
                        : x.buyerTradeFee,
                      precesion
                    )}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(x.quoteTokenAmount, precesion) +
                      " " +
                      x.quoteTokenSymbol}
                  </div>
                  <div className={styles.col}>
                    {helper.formatTime(x.createdAt)}
                  </div>
                </div>
              ))}
            </div>
          </Scrollbar>
        )}
      </div>
      {!isLoggedIn && (
        <div className={styles.requirelogin}>{t("table.require_login")}</div>
      )}
    </div>
  );
};

export default TableSpot;
