import useDarkMode from "@fisch0920/use-dark-mode";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { baseUrl } from "utils/constants.js";
import styles from "./style.module.sass";
import { TRADING_TYPE } from "utils/types.js";
import { useTranslation } from "react-i18next";
import { deci } from "utils/decimal.js";
const AdvancedChart = ({tradingType = TRADING_TYPE.spot}) => {
  const { i18n } = useTranslation();

  const containerRef = useRef(null);
  const darkMode = useDarkMode(false);
  const symbol = useSelector((state) => state.symbolData.symbol);
  const [prevTheme, setPrevTheme] = useState(null);
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
  const [widget, setWidget] = useState(null);

  const intializeTradinView = () => {
    const widgetOptions = {
      symbol: symbol,
      // symbol: "AAPL",
      interval: "1", // Default interval
      container: containerRef.current,
      library_path: "../trading_view/charting_library/", // Path to the charting_library folder
      locale: i18n.language,
      width: "100%",
      session: "24x7",
      theme: darkMode.value ? "Dark" : "Light",
      disabled_features: [
        "header_compare",
        "header_symbol_search",
        "header_quick_search",
        "symbol_search_hot_key",
      ],
      charts_storage_url: "https://saveload.tradingview.com",
      charts_storage_api_version: "1.1",
      client_id: "lunarx.in",
      user_id: "public_user_id",
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        `${baseUrl}/advanced-chart`,
        1,
        {
          maxResponseLength: 1000,
          expectedOrder: "earliestFirst",
        }
        // "https://demo-feed-data.tradingview.com/"
      ),
      fullscreen: false,
      mainSeriesProperties: {
        style: 3, // 3 represents the 'Area' chart type
      },
      custom_formatters: {
        priceFormatterFactory: (symbolInfo, minTick) => {
          return {
            format: (price, signPositive) => {
              return deci(price).toFixed(0)
            },
          };
          return null; // this is to use default formatter;
        },
      },
      time_scale : {
        // bar_spacing : 20,
        // min_bar_spacing : 20
      }
    };
    if (!isTablet) {
      widgetOptions.height = "100%";
    }
    else{
      widgetOptions.height = '700px'
    }
    let _widget = new window.TradingView.widget(widgetOptions);
   
    setWidget(_widget);
    return widget;
  };

  useEffect(() => {
    intializeTradinView();
  }, [symbol, i18n.language]);

  useEffect(() => {
    if (!widget) {
      return;
    }
    window.widget = widget 
    // console.log("widget here")
    widget.onChartReady(() => {
      // console.log("On chart ready called" , tradingType)
      widget
        .activeChart()
        .setChartType(tradingType === TRADING_TYPE.options ? 3 : 1);
        if(isTablet){
          // widget.startFullscreen()
        }
    });
  }, [tradingType, widget]);
  useEffect(() => {
    if ((prevTheme !== null && prevTheme === darkMode.value) || !widget) {
      return;
    }
    widget.onChartReady(() => {
      widget.changeTheme(darkMode.value ? "Dark" : "Light");
    });
    setPrevTheme(darkMode.value);
  }, [darkMode.value, widget, prevTheme]);

  return (
    <div className={styles.charts}>
      <div
        ref={containerRef}
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0px",
        }}
        className="chart-container"
      />
    </div>
  );
};

export default AdvancedChart;
